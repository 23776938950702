import React from "react"
import { Link } from "gatsby"

const Logo = () => (
  <Link to="/">
    <svg className="logo" xmlns="http://www.w3.org/2000/svg" width="600px" height="176.59px" viewBox="0 0 600 176.59">
      <path fill="#000" d="M12.14,1.72C12.25,0.75,13.22,0,14.07,0h1.72c0.54,0,1.5,0.43,1.82,1.07l24.87,46.31h0.21L67.57,1.07
        C67.89,0.43,68.85,0,69.39,0h1.72c0.86,0,1.82,0.75,1.93,1.72l12.11,72.04c0.21,1.39-0.75,2.36-2.04,2.36H70.46
        c-0.97,0-1.93-0.86-2.04-1.72L63.6,41.27h-0.21L45.38,76.01c-0.32,0.64-1.29,1.18-1.82,1.18h-1.93c-0.64,0-1.5-0.54-1.82-1.18
        L21.69,41.27h-0.21L16.75,74.4c-0.11,0.86-0.97,1.72-2.04,1.72H2.07c-1.29,0-2.25-0.97-2.04-2.36L12.14,1.72z"/>
      <path fill="#000" d="M131.89,0c21.44,0,38.59,17.26,38.59,38.7c0,21.44-17.15,38.49-38.59,38.49S93.4,60.14,93.4,38.7
        C93.4,17.26,110.45,0,131.89,0z M131.89,60.03c11.79,0,21.44-9.65,21.44-21.33c0-11.79-9.65-21.55-21.44-21.55
        c-11.69,0-21.33,9.76-21.33,21.55C110.56,50.39,120.2,60.03,131.89,60.03z"/>
      <path fill="#000" d="M183.35,1.93c0-1.07,0.97-1.93,2.04-1.93h2.68l42.77,41.17h0.11V3.11c0-1.07,0.86-2.04,2.04-2.04h12.86
        c1.07,0,2.04,0.96,2.04,2.04v72.15c0,1.07-0.97,1.93-2.04,1.93h-1.72c-0.32,0-1.18-0.32-1.39-0.54l-42.35-42.56h-0.11v39.99
        c0,1.07-0.86,2.04-2.04,2.04h-12.76c-1.07,0-2.04-0.97-2.04-2.04L183.35,1.93z"/>
      <path fill="#000" d="M299.34,0c21.44,0,38.59,17.26,38.59,38.7c0,21.44-17.15,38.49-38.59,38.49s-38.49-17.04-38.49-38.49
        C260.85,17.26,277.9,0,299.34,0z M299.34,60.03c11.79,0,21.44-9.65,21.44-21.33c0-11.79-9.65-21.55-21.44-21.55
        c-11.69,0-21.33,9.76-21.33,21.55C278.01,50.39,287.65,60.03,299.34,60.03z"/>
      <path fill="#000" d="M67.57,99.51c9.01,0,18.87,3.75,26.16,10.08c0.96,0.75,1.07,2.04,0.21,2.89l-8.47,9.01c-0.86,0.75-2.04,0.75-2.89,0.11
        c-4.07-3.75-8.79-5.15-14.04-5.15c-11.69,0-21.12,9.86-21.12,21.65c0,11.69,9.54,21.33,21.23,21.33c3.11,0,6.33-0.43,9.54-1.72
        v-6.22h-6.75c-1.18,0-2.04-0.86-2.04-1.93v-10.61c0-1.18,0.86-2.04,2.04-2.04h21.55c1.07,0,1.93,0.97,1.93,2.04l0.11,28.84
        c0,0.54-0.43,1.39-0.86,1.72c0,0-11.04,6.97-26.59,6.97c-21.33,0-38.49-16.94-38.49-38.38C29.08,116.67,46.23,99.51,67.57,99.51z"/>
      <path fill="#000" d="M109.7,102.52c0-1.07,0.86-2.04,2.04-2.04h32.05c12.86,0,23.37,10.4,23.37,23.16c0,9.86-6.54,17.8-15.87,21.55l14.69,27.23
        c0.75,1.39,0,3.11-1.82,3.11H149.9c-0.86,0-1.5-0.54-1.72-0.97l-14.26-28.41h-7.4v27.34c0,1.07-0.96,2.04-2.04,2.04h-12.76
        c-1.18,0-2.04-0.97-2.04-2.04V102.52z M142.4,132.53c4.4,0,8.15-4.07,8.15-8.58c0-4.5-3.75-8.15-8.15-8.15h-15.87v16.72H142.4z"/>
      <path fill="#000" d="M174.02,172.73l33.56-72.15c0.32-0.64,1.07-1.18,1.82-1.18h1.07c0.75,0,1.5,0.54,1.82,1.18l33.55,72.15
        c0.64,1.39-0.21,2.79-1.82,2.79h-11.9c-1.93,0-2.79-0.64-3.75-2.68l-3.86-8.47h-29.16l-3.86,8.58c-0.54,1.29-1.72,2.57-3.86,2.57
        h-11.79C174.23,175.52,173.38,174.13,174.02,172.73z M218.08,149.79l-8.15-17.69h-0.11l-8.04,17.69H218.08z"/>
      <path fill="#000" d="M264.18,101.12c0.11-0.96,1.07-1.72,1.93-1.72h1.72c0.54,0,1.5,0.43,1.82,1.07l24.87,46.31h0.21l24.87-46.31
        c0.32-0.64,1.29-1.07,1.82-1.07h1.72c0.86,0,1.82,0.75,1.93,1.72l12.11,72.04c0.22,1.39-0.75,2.36-2.04,2.36H322.5
        c-0.97,0-1.93-0.86-2.04-1.72l-4.82-33.13h-0.22l-18.01,34.73c-0.32,0.64-1.29,1.18-1.82,1.18h-1.93c-0.64,0-1.5-0.54-1.82-1.18
        l-18.12-34.73h-0.22l-4.72,33.13c-0.11,0.86-0.97,1.72-2.04,1.72H254.1c-1.29,0-2.25-0.97-2.04-2.36L264.18,101.12z"/>
      <path fill="#000" d="M372.11,1.71c0-0.41,0.37-0.78,0.78-0.78h4.92c0.45,0,0.78,0.37,0.78,0.78v10.41h11.81V1.71c0-0.41,0.33-0.78,0.78-0.78
        h4.92c0.41,0,0.78,0.37,0.78,0.78v27.35c0,0.41-0.37,0.78-0.78,0.78h-4.92c-0.45,0-0.78-0.37-0.78-0.78V18.19h-11.81v10.86
        c0,0.41-0.33,0.78-0.78,0.78h-4.92c-0.41,0-0.78-0.37-0.78-0.78V1.71z"/>
      <path fill="#000" d="M384.48,36.89c8.26,0,14.87,6.65,14.87,14.91c0,8.26-6.61,14.83-14.87,14.83c-8.26,0-14.83-6.57-14.83-14.83
        C369.65,43.54,376.22,36.89,384.48,36.89z M384.48,60.03c4.54,0,8.26-3.72,8.26-8.22c0-4.54-3.72-8.3-8.26-8.3
        c-4.5,0-8.22,3.76-8.22,8.3C376.26,56.31,379.98,60.03,384.48,60.03z"/>
      <path fill="#000" d="M372.73,74.47c0-0.41,0.37-0.78,0.79-0.78h5.08c0.45,0,0.78,0.37,0.78,0.78V91.2c0,2.89,2.15,5.2,5.08,5.2
        c2.97,0,5.16-2.31,5.16-5.2V74.47c0-0.41,0.33-0.78,0.78-0.78h5.08c0.41,0,0.78,0.37,0.78,0.78v17.06c0,6.28-5.29,11.48-11.81,11.48
        c-6.49,0-11.73-5.2-11.73-11.48V74.47z"/>
      <path fill="#000" d="M374.98,135.06l1.94-3.43c0.33-0.54,1.11-0.45,1.4-0.25c0.17,0.08,3.14,2.27,5.87,2.27c1.65,0,2.85-1.03,2.85-2.48
        c0-1.74-1.45-3.06-4.26-4.17c-3.55-1.4-8.01-4.17-8.01-9.13c0-4.09,3.18-8.22,9.54-8.22c4.3,0,7.6,2.19,8.84,3.1
        c0.5,0.29,0.41,1.03,0.25,1.32l-2.11,3.18c-0.29,0.45-1.03,0.83-1.4,0.54c-0.37-0.21-3.39-2.48-5.91-2.48c-1.49,0-2.6,0.99-2.6,2.11
        c0,1.53,1.24,2.68,4.5,4.01c3.22,1.28,8.34,3.8,8.34,9.42c0,4.26-3.68,8.55-9.75,8.55c-5.37,0-8.34-2.23-9.29-3.18
        C374.77,135.81,374.65,135.64,374.98,135.06z"/>
      <path fill="#000" d="M375.16,147.24c0-0.41,0.33-0.78,0.78-0.78h17.1c0.46,0,0.79,0.37,0.79,0.78v4.5c0,0.41-0.33,0.78-0.79,0.78h-11.44v5.12
        h9.42c0.41,0,0.78,0.37,0.78,0.79v4.5c0,0.45-0.37,0.79-0.78,0.79h-9.42v5.58h11.44c0.46,0,0.79,0.37,0.79,0.78v4.5
        c0,0.41-0.33,0.78-0.79,0.78h-17.1c-0.45,0-0.78-0.37-0.78-0.78V147.24z"/>
      <path fill="#000" d="M479.69,66.11c-4.32,2.45-8.37,4.97-12.61,7.1c-6.97,3.5-7.07,3.42-10.59-3.4c-3.72-7.21-7.31-14.48-11.04-21.68
        c-0.87-1.68-0.56-2.33,1.11-3.35c8.03-4.88,16.13-9.68,23.79-15.1c9.65-6.83,20.03-11.62,31.7-13.47c0.92-0.15,2.36,0.49,2.98,1.24
        c4.51,5.48,10.13,8.61,17.31,8.27c6.23-0.3,11.43-2.98,15.32-7.87c1.35-1.7,2.75-2.06,4.83-1.85c10.36,1.06,19.55,5.16,28.18,10.65
        c8.18,5.2,17.19,8.33,26.35,11.16c0.7,0.21,1.4,0.43,2.07,0.7c0.28,0.11,0.5,0.37,0.92,0.68c-2.89,8.09-5.78,16.16-8.66,24.23
        c-0.9,2.52-1.8,5.04-2.62,7.58c-0.56,1.73-1.5,2.71-3.39,2.06c-5.11-1.75-10.22-3.53-15.32-5.32c-0.81-0.28-1.59-0.67-2.59-1.1
        c-0.12,0.67-0.27,1.04-0.24,1.39c1.17,14.16-0.15,28.2-2.03,42.22c-1.96,14.7-7.76,27.68-16.94,39.22
        c-1.31,1.65-2.69,3.28-4.25,4.68c-0.57,0.51-2.1,0.77-2.62,0.38c-13.26-9.96-28.26-11.63-44.2-9.99c-7.31,0.75-14.8,1.05-22.1,0.38
        c-9.56-0.88-17.85-5.55-25.53-11.2c-1.64-1.2-0.66-2.16,0.15-3.22c5.37-7.12,10.77-14.22,16.08-21.39
        c6.17-8.32,9.45-17.85,11.47-27.88c0.9-4.45,1.65-8.92,2.44-13.39C479.77,67.31,479.69,66.73,479.69,66.11z"/>
    </svg>
  </Link>
)

export default Logo
